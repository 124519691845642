import React from 'react';
import Layout from '../components/layout';
import DataWrapChart from '../components/datawrap';
import { graphql } from 'gatsby';

import RevNav from '../components/charges/refSideNav';
import RefTrends from '../components/charges/overallTrends';
import LeAgency from '../components/charges/leAgency';
import Offense from '../components/charges/offenseType';
import DemTrends from '../components/charges/demographics';

const ChargePage = ({ data }) => {
  const orderNodes = data.allIframeIdCsv.nodes;
  const url = orderNodes.map(node => node.id);
  const id = orderNodes.map(node => node.url);
  const obj = Object.create(null);
  url.forEach((e, i) => (obj[e] = id[i]));

  return (
    <Layout>
      <div className="grid-container">
        <div class="notification">
          <div class="columns">
            <div class="column is-3">
              <RevNav></RevNav>
            </div>

            <section className="usa-graphic-list usa-section">
              <div className="grid-container">
                <div class="border-bottom-1 border-accent-cool-darker  padding-bottom-2 margin-bottom-2 usa-prose">
                  <h1 className="text-bold margin-0">Charging</h1>
                </div>
              </div>

              <div className="grid-container">
                <p>
                  {' '}
                  After reviewing a case presented for review, prosecutors
                  decide whether to issue charges. A charge is a formal
                  accusation filed by the Milwaukee County District Attorney's
                  office that a specific person has committed a specific crime,
                  also referred to as filing a criminal complaint or
                  information. Reasons charges are not issued include acceptance
                  into a pre-charge diversion program, insufficient
                  evidence/investigation by law enforcement, or when the charges
                  did not rise to the level of a crime. Data updated to:{' '}
                  <b>11-30-2023</b>
                </p>
              </div>
              <div className="grid-container">
                <DataWrapChart
                  frameborder="0"
                  aria-label="Table"
                  id="datawrapper-chart-Fucs6"
                  src="https://datawrapper.dwcdn.net/8mPcI/9/"
                />
              </div>

              <RefTrends></RefTrends>
              <LeAgency></LeAgency>
              <Offense></Offense>
              <DemTrends></DemTrends>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyNewQuery {
    allIframeIdCsv(filter: { page: { eq: "ref" } }) {
      nodes {
        id
        url
      }
    }
  }
`;

export default ChargePage;
